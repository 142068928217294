import React from 'react'
import Header from '../Header/Header'
import "./Hero.css"
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter'

const Hero = () => {

    const transition = {type: 'spring', duration:3}
    return (
    <div className="hero">
        <div className="blur blur-h"></div>
        <div className="left__side">
            <Header/>
            <div className="the-best-ad">
                <motion.div
                initial={{left:'238px'}}
                whileInView={{left:'8px'}}
                transition={{...transition, type:'tween'}}
                ></motion.div>
                <span>The Best Fitness Club In The City</span>
            </div>

            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body</span>
                </div>
                <div>
                    <span>
                    In here we will help you to shape and build your ideal body                        
                    </span>
                    <p>and live up your life to the fullest</p>
                </div>
            </div>
            <div className="figures">
                <div>
                    <span>
                        <NumberCounter end={30} start={0} delay='4' prefix="+"/>
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={380} start={300} delay='3' prefix="+"/>
                    </span>
                    <span>Members Joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={11} start={0} delay='5' prefix="+"/>
                    </span>
                    <span>Fitness Programs</span>
                </div>
            </div>
            <div className="hero-buttons">
                <button className="btn">Get Started</button>
                <button className="btn">Learn More</button>
            </div>
        </div>
        <div className="right__side">
            <button className="btn">Join Now</button>

            <motion.div 
            transition={transition}
            initial={{right: '-1rem'}}
            whileInView={{right:"4rem"}}
            className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>

            <img src={hero_image} alt="" className="hero-image" />
            <motion.img 
            initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
            transition={{transition}}
            src={hero_image_back} alt="" className="hero_image_back" />
            <motion.div 
            initial={{right:'38rem'}}
            whileInView={{right:'28rem'}}
            transition={{transition}}
            className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burned</span>
                    <span> 200 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Hero
